<template>
     <div class=" service body">
          <div class="container">
               <div class="columns">
                    <div class="column is-6">
                         <div class="vertical-align">
                              <div class="section-header-2">
                                   ITeS & Networking Solutions
                              </div>
                              <div>
                                   <p>
                                        ITeS is changing the way we live and the way we work. CTL excels with its vast experience in ITeS and IBMS
                                        networking, enabling it to design and develop vendor-agnostic solutions. CTL has key skills in IBT (Integrated
                                        Building Technology) and SBT (Smart Building Technology) to enhance system/solution design with specialised
                                        middleware.
                                   </p>
                              </div>
                         </div>
                    </div>
                    <div class="column is-6"><img class="sideimg" src="@/assets/images/ites.png" alt="" /></div>
               </div>

               <div class="columns details">
                    <div class="column is-half ">
                         <div class="line-top">
                              <div class="section-title">
                                   Benefits of ITeS System
                              </div>
                              <div>
                                   <p>
                                        Enhance end user experience by establishing reliable high speed wired and wireless connectivity in secured
                                        platforms.
                                   </p>

                                   <p>
                                        Establishing Connectivity between hardwares, equipment, sensors, softwares and other infrastructure management
                                        devices that can automate infrastructure functionalities and energy management.
                                   </p>

                                   <p>
                                        Centrally Controlled platform to end User Devices based on User Identity, Location, Time, Devices, Device
                                        Health and Connectivity Secure single sign-in access of company’s resources and collaborate from anywhere
                                        around the globe.
                                   </p>

                                   <p>
                                        Reducing administrative burden of infrastructure management using NFV, SDN and SD WAN technologies.
                                   </p>
                                   <p>
                                        Dynamic Control over the Network, Servers and Security systems based on behaviour of Users, Applications, and
                                        Network.
                                   </p>
                              </div>
                         </div>
                    </div>
                    <div class="column is-half ">
                         <div class="line-top">
                              <div class="section-title">
                                   Specific Solutions
                              </div>
                              <p>
                                   Structured Cabling<br />
                              Routing & Switching<br />
                                   Wired & Wireless LAN<br />
                                   Data, Voice & Video<br />
                                   Servers, Storages & Backup<br />
                                   Cloud Computing Services <br />
                                   Network & Server<br />
                                   Virtualisation<br />
                                   Communication and Collaboration Services<br />
                                   Infrastructure Managed Services<br />
                                   Network Management Services<br />
                              </p>

                              <div class="para-large mt-60">
                                   View Projects that have availed CTLs Solution in this category.
                                   <br />
                                   <router-link  :to="{name:'PROJECTS'}" class="knowmore">Know More</router-link>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     export default {};
</script>

<style lang="scss" scoped>

</style>
